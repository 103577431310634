import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import {
  faDesktop,
  faFileCode,
  faCloud,
  faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  return (
    <div id='services' className='services'>
      <h1 className='py-5'>Professional Services</h1>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='box'>
              <div className='circle'>
                <FontAwesomeIcon className='icon' icon={faDesktop} size='2x' />
              </div>
              <h3>Data Engineer</h3>
              <p>
                Big Data, Database and DWH consulting with Apache Spark and
                Amazon Web Services or on Premises.
              </p>
            </div>
          </div>
          {/* - */}
          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='box'>
              <div className='circle'>
                <FontAwesomeIcon className='icon' icon={faFileCode} size='2x' />
              </div>
              <h3>Fullstack Web Development</h3>
              <p>Dynamic websites build ReactJS, Python, Node and Java</p>
            </div>
          </div>
          {/* - */}
          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='box'>
              <div className='circle'>
                <FontAwesomeIcon className='icon' icon={faCloud} size='2x' />
              </div>
              <h3>Cloud Native Development</h3>
              <p>
                Migrate your existing applications and data processing into the
                AWS Cloud or design something new with a Cloud First approach.
              </p>
            </div>
          </div>
          {/* - */}
          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='box'>
              <div className='circle'>
                <FontAwesomeIcon
                  className='icon'
                  icon={faCodeBranch}
                  size='2x'
                />
              </div>
              <h3>Serverless App Development</h3>
              <p>
                Serverless application development can allow you to quickly
                deploy application features without the overhead of managing
                servers 24/7.
              </p>
            </div>
          </div>
          {/* - */}
        </div>
      </div>
    </div>
  );
};

export default Services;
