import React from 'react';

const Privacy = () => {
  return (
    <div id='privacy'>
      <div className='privacy'>
        <h1>Privacy Policy</h1>
        <br />
        <p>
          <h4 className='privacy-heading'>Your rights as the data subject</h4>
          <p>
            You can exercise the following rights at any time using the contact
            details of our data protection officer:
            <ul>
              <li>
                Information on your data stored by us and the processing thereof
                (Art. 15 GDPR)
              </li>{' '}
              <li>Rectification of inaccurate personal data (Art. 16 GDPR)</li>{' '}
              <li>Deletion of your data stored by us (Art. 17 GDPR)</li>{' '}
              <li>
                Restriction of the data processing, provided that we may not
                delete your data due to legal obligations (Art. 18 GDPR)
              </li>
              <li>
                Objection to the processing of your data with us (Art. 21 GDPR)
                and Data portability, provide that you have consented to the
                data processing or have entered into a contract with us (Art. 20
                GDPR).
              </li>
            </ul>
            If you have given us consent, you may withdraw it at any time, which
            will remain in effect in the future. You can contact a supervisory
            authority with a complaint at any time, e.g. the supervisory
            authority of the state of your residence or the authority that
            oversees us as the responsible party. You’ll find a list of
            supervisory authorities (for the non-public area) with their
            respective addresses at:
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
          </p>
        </p>
        <h2>Commentary function</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          When users leave comments on our website, the time of their creation
          and the username selected by the website visitor are saved. This is
          for our security, as we may be prosecuted for any illegal content on
          our website, even if it was created by users.
        </p>
        <p>
          <br />
          <h4 className='privacy-heading'>Legal basis</h4>{' '}
          <p>
            The processing of the data entered as a comment occurs on the basis
            of a legitimate interest (Art. 6 Para. 1 (a) GDPR).
            <br />
            With the commentary feature, our aim is to provide you with an
            uncomplicated way to interact. The information you enter will be
            stored for the purpose of processing the request as well as possible
            follow-up inquiries.
          </p>
        </p>
        <p />
        <h4 className='privacy-heading'>Recipients</h4>{' '}
        <p>Recipients of the data may be processors.</p>
        <p />
        <p>
          <h4 className='privacy-heading'>Retention period</h4>
          The data will be deleted as soon as they are no longer required for
          the reason they were collected. This is generally the case, when the
          communication with the user has ended and the company can see from the
          circumstances that the respective issue has been clarified.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary. Without the
          provision of your personal data, we cannot guarantee you access to our
          commentary function.
        </p>
        <h2 className='privacy-heading'>Newsletter</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          <p>
            Your data will be used exclusively to send you the newsletter you
            subscribed for via e-mail. We request your name so that we can
            address you personally in the newsletter and identify you as needed
            should you want to exercise your rights as a data subject.
          </p>
          <p>
            To receive the newsletter, providing your e-mail address is
            sufficient. When you subscribe to our newsletter, the data you
            provide will be used exclusively for this purpose. Subscribers can
            also be notified by e-mail about circumstances that are relevant to
            the service or registration (such as changes to the newsletter offer
            or technical matters).
          </p>
          <p>
            We need a valid e-mail address to complete your registration. In
            order to verify that a registration is actually made by the owner of
            an e-mail address, we utilise the ‘double opt-in’ procedure. To this
            end, we log the newsletter subscription request, when a confirmation
            e-mail is sent and the receipt of the requested reply. Additional
            data is not collected. The data will be used exclusively for sending
            the newsletter and will not be shared with third parties.
          </p>
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Based on your express consent (Article 6 (1) a DSGVO), we will send
          you our newsletter on a regular basis or comparable information via
          e-mail to your specified e-mail address.
        </p>
        <p>
          The consent to save and use your personal data for the newsletter may
          be withdrawn at any time and remain in effect in the future. Every
          newsletter contains a corresponding link, and you can also unsubscribe
          on this website at any time or inform us of your cancellation via the
          contact option indicated at the end of this privacy policy.
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>Recipients of the data may be processors</p>
        <h4 className='privacy-heading'>Retention period</h4>
        <p>
          The data will be deleted no later than 6 months after processing the
          inquiry.
        </p>
        <p>
          Provided that we enter into a contract together, we will use the
          statutory retention periods in the German Commercial Code
          (Handelsgesetzbuch) and delete your data according to the respective
          stipulated deadlines.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary. However, we can only
          process your inquiry if you provide us with your name, e-mail address
          and the reason for your inquiry.
        </p>
        <h2 className='privacy-heading'>Using Google Analytics</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          This website uses Google Analytics, a web analytics service of Google
          LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043 USA
          (hereinafter: ‘Google’). Google Analytics uses so-called ‘cookies’,
          i.e. text files that are stored on your computer and allow an analysis
          of your use of the website. The information generated by the cookie
          about your use of this website is typically transmitted to a Google
          server in the U.S. and stored there. However, due to the activation of
          IP anonymisation on these websites, your IP address will be truncated
          beforehand by Google within the member states of the European Union or
          in other contracting states of the Agreement on the European Economic
          Area. Only in exceptional cases will the full IP address be sent to a
          Google server in the U.S. and truncated there. On behalf of the
          operator of this website, Google will use this information to evaluate
          your use of the website, summarise reports on website activities and
          provide other services related to website and Internet usage to the
          website operator. The IP address transferred from your browser as part
          of Google Analytics will not be combined with other data from Google.
        </p>
        <p>
          The data processing purposes are the website-use analysis and the
          summary of reports on activities on the website. Based on the use of
          the website and the Internet, other related services will be provided.
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          The processing of the data occurs on the basis of the user’s consent
          (Art. 6 Para. 1 (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          The recipient of the data is Google as the processor. For this, we
          have entered into the corresponding data-processing contract with
          Google.
        </p>
        <h4 className='privacy-heading'>Retention period</h4>
        <p>
          The deletion of the data occurs as soon as they are no longer
          necessary for our recording-keeping purposes.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Google processes your data in the United States of America and is
          subject to the EU-U.S. Privacy Shield:
          https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary, based solely on your
          consent. If you prevent access, this can lead to functional
          limitations on the website.
        </p>
        <h4 className='privacy-heading'>Revocation of consent</h4>
        <p>
          You can prevent the storage of cookies by a corresponding setting in
          your browser software; however, please note that in this case you may
          not be able to use all the functions of this website in their
          entirety. Furthermore, you can prevent the collected data generated by
          the cookie and the data related to your use of the website (including
          your IP address) being transmitted to Google, as well as the
          processing of this data by Google, by downloading and installing the
          browser plug-in available at the following link: browser add-on to
          deactivate Google Analytics.
        </p>
        <p>
          In addition, or as an alternative to the browser add-on, you can
          prevent tracking by Google Analytics on our web pages by clicking this
          link, which will install an opt-out cookie on your device. This will
          prevent data collection by Google Analytics for this website and for
          this browser in the future, as long as the cookie remains installed in
          your browser.
        </p>
        <h4 className='privacy-heading'>Profiling</h4>
        <p>
          With the assistance of the tracking tool Google Analytics, the
          browsing behaviour of the website visitors can be evaluated and their
          respective interests can be analysed. For this analysis, we create a
          pseudonymous user profile.
        </p>
        <h3 className='privacy-heading'>
          Using script libraries (Google Webfonts)
        </h3>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          In order to present our content correctly and graphically appealing
          across all browsers, we use ‘Google Webfonts’ from Google LLC (1600
          Amphitheater Parkway, Mountain View, CA 94043, USA, hereinafter
          ‘Google’) to display fonts on this website.
        </p>
        <p>
          You’ll find the data protection guidelines of the library operator
          Google here: https://www.google.com/policies/privacy/
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of Google Webfonts
          and the associated data transfer to Google (Art. 6 Para. 1 (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          The use of script libraries or font libraries automatically triggers a
          connection to the operator of the library. It is theoretically
          possible – but currently also unclear whether and to what end – that
          the operator, in this case Google, will collect data.
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          The use of script libraries or font libraries automatically triggers a
          connection to the operator of the library. It is theoretically
          possible – but currently also unclear whether and to what end – that
          the operator, in this case Google, will collect data.
        </p>
        <h4 className='privacy-heading'>Retention period</h4>
        <p>
          We do not collect any personal data via the integration of Google
          Webfonts.
        </p>
        <p>
          You’ll find additional information on Google Webfonts at
          https://developers.google.com/fonts/faq and in Google’s privacy
          policy: https://www.google.com/policies/privacy/.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Google processes your data in the United States of America and is
          subject to the EU-U.S. Privacy Shield:
          https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is neither legally nor
          contractually required. However, the correct presentation of
          standard-fonts content is not possible without this provision.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The programming language JavaScript is regularly used to display
          content. Thus, you can opt out of the data processing by disabling
          JavaScript execution in your browser or by installing a JavaScript
          blocker. Please note that this may result in functional limitations on
          the website.
        </p>
        <h2 className='privacy-heading'>Using Adobe Typekit</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          We use Adobe Typekit for the visual design of our website. Typekit is
          a service of Adobe Systems Software Ireland Companies (4-6 Riverwalk,
          Citywest Business Campus, Dublin 24, Republic of Ireland; hereinafter
          ‘Adobe’), which grants us access to a font library. To incorporate the
          fonts we use, your browser must connect to an Adobe server in the U.S.
          and download the fonts we require for our website. Thereby, Adobe
          receives the information that our website retrieved from your IP
          address. For more information about Adobe Typekit, see the Adobe
          privacy policy available at:
          https://www.adobe.com/uk/privacy/policy.html
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of Adobe Typekit
          and the associated data transfer to Adobe (Art. 6 Para. 1 (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          The use of script libraries or font libraries automatically triggers a
          connection to the operator of the library. You’ll find information on
          the use of your data by Adobe Typekit Web Fonts at
          https://typekit.com/ and in the privacy policy of Adobe Typekit:
          https://www.adobe.com/uk/privacy/policies/typekit.html.
        </p>
        <h4 className='privacy-heading'>Retention period</h4>
        <p>
          We do not collect any personal data via the integration of Adobe
          Typekit Web Fonts.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Adobe is certified under the Privacy Shield Agreement, which provides
          a guarantee to comply with European data protection law
          (https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active).
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is neither legally nor
          contractually required. However, the correct presentation of
          standard-fonts content is not possible without this provision.
        </p>
        <h2 className='privacy-heading'>Using Google Maps</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          We use Google Maps on this website. Google Maps is operated by Google
          LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043, USA
          (hereinafter ‘Google’). This allows us to show you interactive maps
          directly on the website, allowing you to conveniently use the map
          feature.
        </p>
        <p>
          You’ll find additional information about Google’s data processing on
          the Google privacy policy page, where you can also change your
          personal privacy settings in the data protection center.
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of Google Maps and
          the associated data transfer to Google (Art. 6 Para. 1 (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          By visiting the website, Google receives information that you have
          accessed the corresponding subpage of our website. This occurs
          regardless of whether Google provides a user account that you are
          logged into or if there is no user account. When you’re logged into
          Google, your data will be directly assigned to your account.
        </p>
        <p>
          If you do not want this assignment in your Google profile, you must
          log out of Google before activating the button. Google stores your
          data as usage profiles and uses them for advertising, market research
          and/or tailor-made design of its website. Such an analysis occurs in
          particular (even for users who are not logged in) to provide
          needs-based advertising and to inform other users of the social
          network about your activities on our website. You have the right to
          object to the formation of these user profiles, but you must submit
          this objection to Google.
        </p>
        <h4 className='privacy-heading'>Retention period</h4>
        <p>
          We do not collect any personal data via the integration of Google
          Maps.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Google processes your data in the United States of America and is
          subject to the EU-U.S. Privacy Shield:
          https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          If you do not want Google to collect, process or use information about
          you via our website, you can disable JavaScript in your browser
          settings. In this case, you cannot or only partially use our website.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          The provision of your personal data is voluntary, based solely on your
          consent. If you prevent access, this can lead to functional
          limitations on the website.
        </p>
        <h2 className='privacy-heading'>Embedded YouTube videos</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          We embed YouTube videos on several of our websites. The operator of
          the respective plugins is YouTube, LLC, 901 Cherry Ave., San Bruno, CA
          94066, USA (hereinafter ‘YouTube’). YouTube, LLC is a subsidiary of
          Google LLC, 1600 Amphitheater Pkwy, Mountain View, CA 94043, USA
          (hereinafter ‘Google’). When you visit a page with the YouTube
          plug-in, a connection to YouTube’s servers will be created, which
          tells YouTube which pages you visit. When you are logged into your
          YouTube account, YouTube can associate your browsing behaviour with
          you personally. You can prevent this by logging out of your YouTube
          account beforehand.
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of YouTube and the
          associated data transfer to Google (Art. 6 Para. 1 (a GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>Visiting YouTube automatically triggers a connection to Google.</p>
        <h4 className='privacy-heading'>
          Retention period and revocation of consent
        </h4>
        <p>
          Everyone who has disabled the storage of cookies for the Google ad
          programme will not have to expect any cookies when watching YouTube
          videos. However, YouTube also stores non-personal usage information in
          other cookies. If you would like to prevent this, you must block the
          storage of cookies in the browser.
        </p>
        <p>
          You’ll find additional information on data protection at ‘YouTube’ in
          the provider’s privacy policy at: https://policies.google.com/privacy.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Google processes your data in the United States of America and is
          subject to the EU-U.S. Privacy Shield:
          https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary, based solely on your
          consent. If you prevent access, this can lead to functional
          limitations on the website.
        </p>
        <h2 className='privacy-heading'>Google AdWords</h2>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          Our website uses Google Conversion Tracking. The operating company of
          the Google AdWords services is Google LLC, 1600 Amphitheater Parkway,
          Mountain View, CA 94043, USA. If you have reached our website via a
          Google advertisement, Google Adwords will place a cookie on your
          computer. The conversion tracking cookie is used when a user clicks on
          an ad from Google.
        </p>
        <p>
          If the user visits certain pages on our website, and the cookie has
          not expired, we and Google can recognise that the user clicked on the
          ad and was redirected to this page. Every Google AdWords customer
          receives a different cookie. Thus, cookies cannot be tracked by the
          websites of AdWords customers. The information gathered by the
          conversion cookie is used to generate conversion statistics for
          AdWords customers who have opted for conversion tracking. The
          customers see the total number of users who clicked on their
          advertisement and were redirected to a conversion tracking tag page.
          However, they do not receive information that personally identifies
          users.
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of Google AdWords
          and the associated data transfer to Google (Art. 6 Para. 1 (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          Whenever you visit our website, your personal information, including
          your IP address, is transferred to Google in the U.S. This personal
          information is saved by Google. Google may transfer such personal data
          collected through the technical process to third parties.
        </p>
        <p>
          We do not receive information from Google that could identify the data
          subject.
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          These cookies lose their validity after 30 days and are not used for
          personal identification.
        </p>
        <h4 className='privacy-heading'>Third country transfers</h4>
        <p>
          Google processes your data in the United States of America and is
          subject to the EU-U.S. Privacy Shield:
          https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4 className='privacy-heading'>Revocation of consent</h4>
        <p>
          If you do not want to participate in the tracking, you can reject the
          required cookie use – for example via a general browser setting that
          disables the automatic use of cookies or configures your browser to
          block cookies from the domain ‘googleleadservices.com’.
        </p>
        <p>
          Please note that you should not delete the opt-out cookies as long as
          you do not want to save measurement data. If you have deleted all your
          cookies in the browser, you must use the respective opt-out cookie
          again.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary, based solely on your
          consent. If you prevent access, this can lead to functional
          limitations on the website.
        </p>
        <h3 className='privacy-heading'>Using Google Remarketing</h3>
        <h4 className='privacy-heading'>Type and purpose of the processing</h4>
        <p>
          This website uses the remarketing function of Google Inc. The
          operating company of the Google Remarketing services is Google LLC,
          1600 Amphitheater Parkway, Mountain View, CA 94043, United States
          (hereinafter “Google”).
        </p>
        <p>
          The function is designed to present interest-based ads to website
          visitors within the Google advertising network. A so-called ‘cookie’
          is saved in the browser of the website visitor, which makes it
          possible to recognise the visitor when he or she visits websites that
          belong to the advertising network of Google. On these pages, visitors
          can be presented with ads related to content that the visitor
          previously viewed on websites using Google’s remarketing feature.
        </p>
        <h4 className='privacy-heading'>Legal basis</h4>
        <p>
          Your consent is the legal basis for the integration of Google
          Remarketing and the associated data transfer to Google (Art. 6 Para. 1
          (a) GDPR).
        </p>
        <h4 className='privacy-heading'>Recipients</h4>
        <p>
          With every visit of our website, personal data, including your IP
          address, are transmitted to Google in the U.S. These personal data are
          saved by Google. Google may transfer these personal data collected
          through the technical process to third parties.
        </p>
        <p>
          We do not receive any information from Google that could identify the
          data subject.
        </p>
        <h4 className='privacy-heading'>Revocation of consent</h4>
        <p>
          According to its own statements, Google does not collect any personal
          data during this process. However, if you still do not want Google’s
          Remarketing feature, you can always disable it in general via the
          corresponding settings at
          https://support.google.com/adwordspolicy/answer/143465. Alternatively,
          you can disable the use of cookies for interest-based advertising via
          the advertising network initiative by following the instructions at:
          http://www.networkadvertising.org/managing/opt_out.asp.
        </p>
        <h4 className='privacy-heading'>Mandatory or required provision</h4>
        <p>
          The provision of your personal data is voluntary, based solely on your
          consent. If you prevent access, this can lead to functional
          limitations on the website.
        </p>
        <h3 className='privacy-heading'>SSL encryption</h3>
        <p>
          To protect the security of your data during transmission, we use
          state-of-the-art encryption methods (such as SSL) via HTTPS.
        </p>
        <h3 className='privacy-heading'>Revision of our privacy policy</h3>
        <p>
          We reserve the right to amend this privacy policy so that it always
          complies with current legal requirements or to implement changes to
          our services in the privacy policy, e.g. when introducing new
          services. Your next visit will be subject to the new privacy policy.
        </p>
        <h3 className='privacy-heading'>
          Questions for the data protection officer
        </h3>
        <p>
          If you have any questions pertaining to data protection, please send
          us an e-mail or contact the person responsible for data protection in
          our organization:
        </p>
        <p>Kevin Horgan Email: info@balancedcode.com</p>
        <p>
          The privacy policy was created via the activeMind AG privacy policy
          generator (Version: #2018-09-24).
        </p>
        <br />
      </div>
    </div>
  );
};

export default Privacy;
