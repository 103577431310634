import React from 'react';
import author from '../images/Kevin_Profile_HeadOnly_Casual.jpg';
const AboutMe = () => {
  return (
    <div id='about' className='container py-5'>
      <div className='row'>
        <div className='col-lg-6 col-xm-12'>
          <div className='photo-wrap mb-5'>
            <img className='profile-img' src={author} alt='Profile Image...' />
          </div>
        </div>
        <div className='col-lg-6 col-xm-12'>
          <h1 className='about-heading'>About Me</h1>
          <p>
            Kevin Horgan is the creator of Balanced Code. A veteran over 25
            years in the technology industry providing professional consulting
            services on migrating to the Cloud, Big Data / BI Consulting and
            Cloud Native Application Development.
          </p>
          <p>Specialised on AWS Cloud Technologies</p>
          <h4 className='about-heading'>Certifications</h4>
          <li>AWS Certified Solutions Architect Associate</li>
          <li>PSM I Professional Scrum Master</li>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
