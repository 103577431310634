import React from 'react';

const Cookie = () => {
  return (
    <div id='cookie'>
      <div className='cookie'>
        <h1>cookie Policy</h1>
        <br />
        <p>
          <h4 className='cookie-heading'>What Are cookie </h4>
          <p>
            As is common practice with almost all professional websites this
            site uses cookie, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookie. We will also share how you can prevent these
            cookie from being stored however this may downgrade or 'break'
            certain elements of the sites functionality. For more general
            information on cookie see the Wikipedia article on HTTP cookie.
          </p>
        </p>
        <h4 className='cookie-heading'>How We Use Cookies</h4>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <p>
          <br />
          <h4 className='cookie-heading'>Disabling Cookies</h4>{' '}
          <p>
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
          </p>
        </p>
        <p />
        <h4 className='cookie-heading'>The Cookies We Set</h4>{' '}
        <p>
          <ul>
            <li>
              Account related cookies
              <br />
              If you create an account with us then we will use cookies for the
              management of the signup process and general administration. These
              cookies will usually be deleted when you log out however in some
              cases they may remain afterwards to remember your site preferences
              when logged out.
            </li>
            <li>
              Login related cookies
              <br />
              We use cookies when you are logged in so that we can remember this
              fact. This prevents you from having to log in every single time
              you visit a new page. These cookies are typically removed or
              cleared when you log out to ensure that you can only access
              restricted features and areas when logged in.
            </li>
            <li>
              Email newsletters related cookies
              <br /> This site offers newsletter or email subscription services
              and cookies may be used to remember if you are already registered
              and whether to show certain notifications which might only be
              valid to subscribed/unsubscribed users.
            </li>
            <li>
              Surveys related cookies
              <br />
              From time to time we offer user surveys and questionnaires to
              provide you with interesting insights, helpful tools, or to
              understand our user base more accurately. These surveys may use
              cookies to remember who has already taken part in a survey or to
              provide you with accurate results after you change pages.
            </li>
            <li>
              Forms related cookies
              <br />
              When you submit data to through a form such as those found on
              contact pages or comment forms cookies may be set to remember your
              user details for future correspondence.
            </li>
            <li>
              Site preferences cookies
              <br />
              In order to provide you with a great experience on this site we
              provide the functionality to set your preferences for how this
              site runs when you use it. In order to remember your preferences
              we need to set cookies so that this information can be called
              whenever you interact with a page is affected by your preferences.
            </li>
          </ul>
        </p>
        <p />
        <p>
          <h4 className='cookie-heading'>Third Party Cookies</h4>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
          <br />
          <ul>
            <li>
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content. For more information on
              Google Analytics cookies, see the official Google Analytics page.
            </li>
            <li>
              From time to time we test new features and make subtle changes to
              the way that the site is delivered. When we are still testing new
              features these cookies may be used to ensure that you receive a
              consistent experience whilst on the site whilst ensuring we
              understand which optimisations our users appreciate the most.
            </li>
            <li>
              The Google AdSense service we use to serve advertising uses a
              DoubleClick cookie to serve more relevant ads across the web and
              limit the number of times that a given ad is shown to you.
              <br /> For more information on Google AdSense see the official
              Google AdSense privacy FAQ.
            </li>
            <li>
              We also use social media buttons and/or plugins on this site that
              allow you to connect with your social network in various ways. For
              these to work the social media sites, will set cookies through our
              site which may be used to enhance your profile on their site or
              contribute to the data they hold for various purposes outlined in
              their respective privacy policies.
            </li>
          </ul>
        </p>
        <h4 className='cookie-heading'>More Information </h4>
        <p>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site. This Cookies
          Policy was created with the help of the{' '}
          <b>
            Cookies Policy Template Generator and the Terms and Conditions
            Template
          </b>
          . However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
          <br />
          Email: info@balancedcode.com
        </p>
      </div>
    </div>
  );
};

export default Cookie;
