import React from 'react';
import Typed from 'react-typed';

const Header = () => {
  return (
    <div id='home' className='header-wraper'>
      <div className='main-info'>
        <h1>Web and Cloud Native Development</h1>
        <Typed
          className='typed-text'
          strings={[
            'Full-Stack Web Development with Python / Flask',
            'Cloud Native / Serverless Development with Python or NodeJS',
            'OpenCV Computer Vision',
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />
      </div>
    </div>
  );
};

export default Header;
