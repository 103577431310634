import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-2 col-sm-6'>
            <div className='row'>
              <div className='col'>
                <Link
                  smooth={true}
                  to='imprint'
                  offset={-110}
                  className='nav-link'
                  href='#'
                >
                  Imprint <span className='sr-only'>(current)</span>
                </Link>
                <Link
                  smooth={true}
                  to='privacy'
                  offset={-110}
                  className='nav-link'
                  href='#'
                >
                  Privacy Policy <span className='sr-only'>(current)</span>
                </Link>
                <Link
                  smooth={true}
                  to='cookie'
                  offset={-110}
                  className='nav-link'
                  href='#'
                >
                  Cookie Policy <span className='sr-only'>(current)</span>
                </Link>
              </div>
            </div>
          </div>

          <div className='col-lg-5 col-md-5 col-sm-6 align-items-center'>
            <div className='d-flex justify-content-center'>
              <FacebookShareButton
                url={'https://www.youtube.com/balancedcode'}
                quote={'Fullstack Developer'}
                hashtag='#javascript'
              >
                <FacebookIcon className='mx-3' size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={'https://www.youtube.com/balancedcode'}
                quote={'Fullstack Developer'}
                hashtag='#javascript'
              >
                <TwitterIcon className='mx-3' size={36} />
              </TwitterShareButton>
              <RedditShareButton
                url={'https://www.youtube.com/balancedcode'}
                quote={'Fullstack Developer'}
                hashtag='#javascript'
              >
                <RedditIcon className='mx-3' size={36} />
              </RedditShareButton>

              <LinkedinShareButton
                url={'../html/legal.html'}
                quote={'Imprint'}
                hashtag='Legal'
              >
                <LinkedinIcon className='mx-3' size={36} />
              </LinkedinShareButton>
            </div>
            <p className='pt-3 text-center'>
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Balanced Code GmbH | All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
