import React from 'react';

const Imprint = () => {
  return (
    <div id='imprint'>
      <div className='imprint'>
        <h1>Imprint / Legal</h1>
        <br />
        <p>
          <h4 className='imprint-heading'>Address </h4>
          Balanced Code GmbH <br />
          Binger Strasse 41 <br />
          55218 Ingelheim am Rhein <br />
          Germany <br />
        </p>
        <h4 className='imprint-heading'>Contact</h4>
        Telephone: +49 6132 805389 <br />
        Website: http://www.balancedcode.com
        <p>
          <br />
          <h4 className='imprint-heading'>VAT number</h4> VAT identification
          number in accordance with section 27 a of the German VAT act:
          DE267877586{' '}
        </p>
        <p />
        <h4 className='imprint-heading'>
          Person responsible for content in accordance with 55 Abs. 2 RStV
        </h4>{' '}
        Kevin Horgan C/O Balanced Code GmbH <p />
        <p>
          <h4 className='imprint-heading'>Data protection contact</h4> If you
          have questions about the collection, processing or use of your
          personal data or if you need to contact us in relation to information,
          correction, blocking or deletion of data as well as retraction of any
          permissions granted, please send an email via our Contact form. You
          can also write to:
          <br /> <br />
          Balanced Code GmbH <br />
          Binger Strasse 41 <br />
          55218 Ingelheim am Rhein <br />
          Germany <br />
        </p>
        <h2 className='imprint'>Disclaimer </h2>
        <h4 className='imprint-heading'>Accountability for content</h4> The
        contents of our pages have been created with the utmost care. However,
        we cannot guarantee the contents’ accuracy, completeness or topicality.
        According to statutory provisions, we are furthermore responsible for
        our own content on these web pages. In this context, please note that we
        are accordingly not obliged to monitor merely the transmitted or saved
        information of third parties, or investigate circumstances pointing to
        illegal activity. Our obligations to remove or block the use of
        information under generally applicable laws remain unaffected by this as
        per §§ 8 to 10 of the Telemedia Act (TMG).{' '}
        <h4 className='imprint-heading'>Accountability for links</h4>
        Responsibility for the content of external links (to web pages of third
        parties) lies solely with the operators of the linked pages. No
        violations were evident to us at the time of linking. Should any legal
        infringement become known to us, we will remove the respective link
        immediately.
        <h4 className='imprint-heading'>Copyright</h4> Our web pages and their
        contents are subject to German copyright law. Unless expressly permitted
        by law (§ 44a et seq. of the copyright law), every form of utilizing,
        reproducing or processing works subject to copyright protection on our
        web pages requires the prior consent of the respective owner of the
        rights. Individual reproductions of a work are allowed only for private
        use, so must not serve either directly or indirectly for earnings.
        Unauthorized utilization of copyrighted works is punishable (§ 106 of
        the copyright law).
        <h4 className='imprint-heading'>
          Using plug-ins from social networks
        </h4>{' '}
        This website uses social plug-ins from the following social networks:
        Facebook, operated by Facebook Inc., 1601 S. California Ave, Palo Alto,
        CA 94304, USA; Google+, operated by Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043 USA; and Twitter, operated by Twitter
        Inc., Folsom St., Suite 600, San Francisco, CA 94107, USA. The plug-ins
        are represented by the logo of each social network. In general terms,
        this connection that your browser makes as soon as you open our website
        uses the social plug-ins to create a direct connection with the
        Facebook, Google and Twitter servers. The content of the plug-ins is
        transferred directly to your browser by Facebook, Google and Twitter
        which then link your browser to the website you visit. Therefore we have
        no influence on the scope of the data that Facebook, Google and Twitter
        collect in this way. According to our current level of knowledge, they
        include the following information: The social network operators receive
        the information that you have accessed on the various pages of our
        website through their social plug-ins. If you are logged into a social
        network at the same time, the operator can allocate the relevant visits
        to your account in the corresponding social network. If you interact
        further with the social plug-ins, that is, by clicking on the “Like”
        button or commenting, then the corresponding information will be
        transmitted to the relevant social network. We understand that the
        following data will be transmitted:
        <ul>
          <li>IP address</li>
          <li>Browser version and operating system</li>
          <li>Previous web pages visited (referrer) if you followed a link</li>
          <li>Screen resolution</li>
          <li>Plug-ins installed such as Adobe® Flash® or Adobe® Reader®</li>
          <li>URL of the page to which the Facebook Like button is linked</li>
        </ul>{' '}
        Even if you are not a member of one of these social networks, it is
        possible that they can learn your IP address through the social plug-in
        and may store it. Details about the purpose and scope of data
        processing, collection and use by Facebook, Google and Twitter, and your
        rights and settings options relating to them may be found in the privacy
        guidelines:
        <ul>
          <li>Facebook: http://www.facebook.com/policy.php/li</li>
          <li>Google+: https://developers.google.com/+/web/buttons-policy</li>
          <li>Twitter: http://www.twitter.com/privacy </li>
        </ul>
        If you are a member of one of these social networks and do not want them
        to collect information about you from our website and connect it to your
        membership data, you should log out of the social network before you
        access our website. You can prevent data from being transmitted. Use the
        security settings in your browser to do this (e.g., Internet Explorer,
        Firefox, Google Chrome, Safari). Instructions can be found in the “Help”
        section of your browser.
        <h4 className='imprint-heading'>
          Changes to these data protection provisions{' '}
        </h4>
        Balanced Code GmbH reserves the right to change these data protection
        provisions at any time in conformity with current data protection
        legislation. Ingelheim, May 2021
      </div>
    </div>
  );
};

export default Imprint;
